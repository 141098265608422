import React, { useRef, useState, useEffect, useCallback, Fragment } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import useMobile from "../Hooks/Mobile";
import useDevice from "../Hooks/Device";
import { event } from "../../lib/gtag";

import SideMenu from "../SideMenu";
import SubMenuItems from "../SubMenuItems";
import EVShowBanner from "../EVShowBanner";

import { vehicleItems, qaVehicleItems, helpItems, qaHelpItems, toolsItems, qaToolsItems, servicesItems, qaServicesItems } from "../SubMenuItems/menuItems";

import styles from "./styles.module.scss";
import NevoLogo from "../../public/assets/images/NevoLogo";
import Hamburger from "../../public/assets/icons/hamburger";

const headerHeights = [
	{ big: 153, small: 132 }, // 1 item
	{ big: 197, small: 169 }, // 2 items
	{ big: 236, small: 203 }, // 3 items
	{ big: 284, small: 243 }, // 4 items
	{ big: 329, small: 280 }, // 5 items
	{ big: 369, small: 317 } // 6 items
];

// boolean:
// transparent - removes the backround
// black - fades the background with black color
// blackStyle - blackFont leaves the font black in any case
interface IHeader {
	transparent?: boolean;
	noShadowBox?: boolean;
	black?: boolean;
	blackStyle?: boolean;
	renderHeader?: boolean;
	hideBanner?: boolean;
}

const Header = ({ transparent = false, noShadowBox = false, black = false, blackStyle = false, renderHeader = true, hideBanner }: IHeader) => {
	const [displayMenu, setDisplayMenu] = useState(false);

	const [headerAnimation, setHeaderAnimation] = useState(false);
	const [fixedHeader, setFixedHeader] = useState(false);
	const [prevScrollY, setPrevScrollY] = useState(0);
	const [hideHeader, setHideHeader] = useState(false);

	const [displaySubVehicles, setDisplaySubVehicles] = useState(false);
	const [displaySubHelp, setDisplaySubHelp] = useState(false);
	const [displaySubTools, setDisplaySubTools] = useState(false);
	const [displaySubServices, setDisplaySubServices] = useState(false);

	const router = useRouter();
	const mobile = useMobile(1120);
	const device = useDevice();

	const headerRef = useRef<HTMLDivElement>(null);

	const currentPath = router.asPath.split("?")[0];

	const [VehicleItems, vehiclesCount]: any = SubMenuItems({ items: vehicleItems, qaItems: qaVehicleItems, eventType: "Header" });
	const [HelpItems, helpCount]: any = SubMenuItems({ items: helpItems, qaItems: qaHelpItems, eventType: "Header" });
	const [ToolsItems, toolsCount]: any = SubMenuItems({ items: toolsItems, qaItems: qaToolsItems, eventType: "Header" });
	const [ServicesItems, servicesCount]: any = SubMenuItems({ items: servicesItems, qaItems: qaServicesItems, eventType: "Header" });

	// Adapt Header Height on Hover
	const adaptHeaderHeight = useCallback(() => {
		if (headerRef && headerRef.current) {
			let targetHeight;

			const size = window.innerWidth > 1440 ? "big" : "small";

			targetHeight = displaySubTools
				? headerHeights[toolsCount][size]
				: displaySubHelp
				? headerHeights[helpCount][size]
				: displaySubVehicles
				? headerHeights[vehiclesCount][size]
				: displaySubServices
				? headerHeights[servicesCount][size]
				: "";

			if (typeof targetHeight === "number") headerRef.current.style.height = `${targetHeight}px`;
			else headerRef.current.style.height = "";
		}
	}, [displaySubTools, toolsCount, displaySubHelp, helpCount, displaySubVehicles, vehiclesCount, displaySubServices, servicesCount]);

	useEffect(() => {
		adaptHeaderHeight();

		window.addEventListener("resize", adaptHeaderHeight);
		return () => window.removeEventListener("resize", adaptHeaderHeight);
	}, [adaptHeaderHeight]);
	// End of Adaptation

	// Fix and hide header on scroll
	const fixHeader = useCallback(() => {
		const scrollY = window.scrollY;

		// Adding Fixed Header Style
		if (scrollY === 0 && fixedHeader) {
			setFixedHeader(false);
		} else if (scrollY > 30 && !fixedHeader) setFixedHeader(true);

		// Hiding/Showing Header

		if (!headerAnimation) {
			if ((prevScrollY > scrollY || scrollY === 0) && hideHeader) {
				setHeaderAnimation(true);

				setTimeout(() => {
					headerRef?.current?.classList.remove(styles.showHeader);
					setHideHeader(false);

					setHeaderAnimation(false);
				}, 490);

				headerRef?.current?.classList.add(styles.showHeader);
			} else if (prevScrollY < scrollY && scrollY > 100 && !hideHeader) {
				setHeaderAnimation(true);

				setTimeout(() => {
					headerRef?.current?.classList.remove(styles.hideHeader);
					setHideHeader(true);

					setHeaderAnimation(false);
				}, 490);

				headerRef?.current?.classList.add(styles.hideHeader);
			}
		}

		setPrevScrollY(scrollY);
	}, [fixedHeader, headerAnimation, hideHeader, prevScrollY]);

	useEffect(() => {
		fixHeader();
		window.addEventListener("scroll", fixHeader);
		return () => window.removeEventListener("scroll", fixHeader);
	}, [fixHeader]);

	const isDisplaySubNav = displaySubVehicles || displaySubHelp || displaySubTools || displaySubServices;

	useEffect(() => {
		const htmlDoc = document.children[0] as HTMLElement;

		if (isDisplaySubNav) {
			htmlDoc.style.overflow = "hidden";
			document.body.style.overflow = "hidden";
		} else {
			htmlDoc.style.overflow = "";
			document.body.style.overflow = "";
		}

		return () => {
			htmlDoc.style.overflow = "";
			document.body.style.overflow = "";
		};
	}, [isDisplaySubNav]);

	const openSideMenu = () => {
		const htmlDoc = document.children[0] as HTMLElement;
		htmlDoc.style.overflow = "hidden";
		document.body.style.overflow = "hidden";
		event("clickedOnHamburgerMenu");
		setDisplayMenu(true);
	};

	const hideSubNav = () => {
		setDisplaySubVehicles(false);
		setDisplaySubHelp(false);
		setDisplaySubTools(false);
		setDisplaySubServices(false);
	};

	const headerStyle =
		styles.headerContainer +
		(fixedHeader ? ` ${styles.fixedHeader}` : "") +
		(isDisplaySubNav ? ` ${styles.headerContainerOpenned}` : "") +
		(transparent ? ` ${styles.transparentHeader}` : "") +
		(black ? ` ${styles.blackHeader}` : "") +
		(blackStyle ? ` ${styles.blackStyle}` : "");

	const renderNavButtons = () => {
		return (
			<Fragment>
				<div className={styles.navButton}>
					<span
						className="nav-button"
						onMouseEnter={() => {
							hideSubNav();
							setDisplaySubVehicles(true);
						}}
						onClick={() => event("vehiclesHeader")}
					>
						<Link href="/vehicles/all">Browse Vehicles</Link>
					</span>

					{
						<p
							className={
								`${styles.coolUnderline} ` +
								((currentPath.match("vehicle") && !currentPath.match("finance|leasing|lease|comparison") && !isDisplaySubNav) || displaySubVehicles ? styles.displayUnderline : "")
							}
						>
							&nbsp;
						</p>
					}

					{displaySubVehicles && <div className={styles.subNavButtonHolder}>{VehicleItems}</div>}
				</div>

				<div className={styles.navButton}>
					<span
						className="nav-button"
						onMouseEnter={() => {
							hideSubNav();
							setDisplaySubHelp(true);
						}}
						onClick={() => event("helpHeader")}
					>
						<Link href="/learn/articles">Help & Advice</Link>
					</span>

					{<p className={`${styles.coolUnderline} ` + ((currentPath.match("learn|post|news") && !isDisplaySubNav) || displaySubHelp ? styles.displayUnderline : "")}>&nbsp;</p>}

					{displaySubHelp && <div className={styles.subNavButtonHolder}>{HelpItems}</div>}
				</div>

				<div className={styles.navButton}>
					<span
						className="nav-button default-cursor"
						onMouseEnter={() => {
							hideSubNav();
							setDisplaySubTools(true);
						}}
					>
						Tools
					</span>

					{<p className={`${styles.coolUnderline} ` + ((currentPath.match("journeys|comparison") && !isDisplaySubNav) || displaySubTools ? styles.displayUnderline : "")}>&nbsp;</p>}

					{displaySubTools && <div className={styles.subNavButtonHolder}>{ToolsItems}</div>}
				</div>

				<div className={styles.navButton}>
					<span
						className="nav-button default-cursor"
						onMouseEnter={() => {
							hideSubNav();
							setDisplaySubServices(true);
						}}
					>
						Services
					</span>

					{
						<p
							className={
								`${styles.coolUnderline} ` + ((currentPath.match("finance|leasing|lease|bank-of-ireland") && !isDisplaySubNav) || displaySubServices ? styles.displayUnderline : "")
							}
						>
							&nbsp;
						</p>
					}

					{displaySubServices && <div className={styles.subNavButtonHolder}>{ServicesItems}</div>}
				</div>

				{/* Nevo Advisory */}
				<div className={styles.navButton + " " + styles.navCharging}>
					<span className={`nav-button ${styles.navChargingSpan}`}>
						<a className={styles.navChargingA} onClick={() => event("nevoAdvisoryHeader")} href="https://nevoadvisory.ie" target="_blank" rel="noreferrer">
							Nevo Advisory
						</a>
					</span>
				</div>
			</Fragment>
		);
	};

	if (!renderHeader) return <Fragment></Fragment>;

	return (
		<Fragment>
			<div
				ref={headerRef}
				className={headerStyle}
				onMouseLeave={() => {
					hideSubNav();
				}}
				style={{
					display: hideHeader ? "none" : "",
					boxShadow: noShadowBox ? "none" : ""
				}}
			>
				<Link href="/" passHref>
					<div
						className={"nevo-logo " + styles.nevoLogo}
						onMouseEnter={() => {
							hideSubNav();
						}}
					>
						<NevoLogo color={isDisplaySubNav || fixedHeader ? "#1A1C1F" : blackStyle ? "#1A1C1F" : transparent ? "white" : "#1A1C1F"} />
					</div>
				</Link>

				{mobile === false && device === "Desktop" && <nav className={styles.desktopNav}>{renderNavButtons()}</nav>}

				<div className={styles.hamburgerHolder}>
					<div
						className={styles.hamburgerIcon}
						onMouseEnter={() => {
							hideSubNav();
						}}
						onClick={openSideMenu}
					>
						<Hamburger color={isDisplaySubNav || fixedHeader ? "#1A1C1F" : blackStyle ? "#1A1C1F" : transparent ? "white" : "#1A1C1F"} />
					</div>
				</div>
			</div>

			{!hideBanner && <EVShowBanner />}

			{isDisplaySubNav && <div className="fading-cover">&nbsp;</div>}

			{displayMenu && <SideMenu hideMenu={() => setDisplayMenu(false)} />}
		</Fragment>
	);
};

export default Header;
