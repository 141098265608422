import React from "react";
import Link from "next/link";

import { links } from "../../helper/constants";
import { event } from "../../lib/gtag";

import styles from "./styles.module.scss";

interface EVShowBannerProps {
	staticBanner?: boolean;
	financePage?: boolean;
}

export default function EVShowBanner({ staticBanner, financePage }: EVShowBannerProps) {
	return (
		<div className={`${styles.banner} ${staticBanner && styles.staticBanner} ${financePage && styles.financePageBanner}`}>
			<p className={styles.text}>
				The Nevo Electric Vehicle Show, in partnership with Bank of Ireland, is back!{" "}
				<Link href={links.ev_talk_show_page} passHref>
					<span className={styles.link} onClick={() => event("evShowBlueTopBannerRegisterClick")}>
						Click for event details &gt;
					</span>
				</Link>
			</p>
		</div>
	);
}
